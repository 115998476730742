import gsap from "gsap"

export function animateElement(target) {
    let attrVal;
    let animDelay;
    let attrDelayPart;

    if (target.getAttribute("data-anim")) {
        attrVal = target.getAttribute("data-anim");
    } else {
        attrVal = target.getAttribute("data-anim-child");
    }

    if (attrVal.includes("delay-")) {
        attrDelayPart = attrVal.split(" ").pop();
        animDelay = attrDelayPart.substr(attrDelayPart.indexOf("-") + 1) / 10;
    }

    if (attrVal.includes("counter")) {
        counter(target, animDelay);
    } else if (attrVal.includes("line-chart")) {
        lineChart(target, animDelay);
    } else if (attrVal.includes("pie-chart")) {
        pieChart(target, animDelay);
    } else if (attrVal.includes("split-lines")) {
        //splitLines(target, animDelay);
    } else {
        target.classList.add("is-in-view");
    }
}

function pieChart(target, animDelay = 0) {
    let counterVal = target.getAttribute("data-percent");
    const chartBar = target.querySelector(".js-chart-bar");

    if (counterVal < 0) {
        counterVal = 0;
    }
    if (counterVal > 100) {
        counterVal = 100;
    }

    gsap.fromTo(
        chartBar,
        {
            drawSVG: `0%`,
        },
        {
            delay: 0.3 + animDelay,
            duration: 1.4,
            ease: "power3.inOut",
            drawSVG: `${counterVal}%`,

            onStart: () => {
                chartBar.classList.remove("bar-stroke-hidden");
            },
        }
    );

    let object = { count: 0 };
    const barPercent = target.querySelector(".js-chart-percent");

    gsap.to(object, {
        count: counterVal,
        delay: 0.45 + animDelay,
        duration: 1,
        ease: "power3.inOut",

        onUpdate: function () {
            barPercent.innerHTML = Math.round(object.count) + "%";
        },
    });
}

function lineChart(target, animDelay = 0) {
    const counterVal = target.getAttribute("data-percent");

    gsap.fromTo(
        target.querySelector(".js-bar"),
        {
            scaleX: 0,
        },
        {
            delay: 0.45 + animDelay,
            duration: 1,
            ease: "power3.inOut",
            scaleX: counterVal / 100,
        }
    );

    let object = { count: 0 };
    const barPercent = target.querySelector(".js-number");

    gsap.to(object, {
        count: counterVal,
        delay: 0.45 + animDelay,
        duration: 1,
        ease: "power3.inOut",

        onUpdate: function () {
            barPercent.innerHTML = Math.round(object.count);
        },
    });
}

function counter(target, animDelay = 0) {
    const counterVal = target.getAttribute("data-counter");
    const counterAdd = target.getAttribute("data-counter-add");
    const totalDelay = animDelay;
    let symbols = "";

    let object = { count: 0 };
    const counterNum = target.querySelector(".js-counter-num");

    if (counterAdd) {
        symbols = counterAdd;
    }

    gsap.to(object, {
        count: counterVal,
        delay: totalDelay,
        duration: 1.8,
        ease: "power3.inOut",

        onUpdate: function () {
            counterNum.innerHTML = Math.round(object.count) + symbols;
        },
    });
}

export const Header = (function () {
    let navList;
    let navBtnListBack;
    let menuDeepLevel;
    let timeline = gsap.timeline();

    function updateVars() {
        if (document.querySelector(".desktopMenu")) {
            navList = document.querySelector(".desktopMenu .js-navList");
        } else {
            navList = document.querySelector(".js-navList");
        }

        navBtnListBack = document.querySelectorAll(".js-nav-list-back");        
        menuDeepLevel = 0;
    }

    function init() {
        updateVars();
        menuListBindEvents();
    }

    function deepLevelCheck(level) {
        return level;
    }

    function menuListBindEvents() {        
        const listItems = document.querySelectorAll(
            ".js-navList .menu-item-has-children"
        );        
        if (!listItems.length) return;

        navBtnListBack.forEach((el) => {            
            el.addEventListener("click", () => {                
                const visibleList = navList.querySelector("ul.-is-active");
                const parentList = visibleList.parentElement.parentElement;

                menuDeepLevel--;
                menuListStepAnimate(visibleList, parentList, menuDeepLevel);
            });
        });

        listItems.forEach((el) => {
            const parentLink = el.querySelector("li > a");
            //parentLink.removeAttribute("href");

            parentLink.addEventListener("click", () => {
                const parent = el.parentElement;
                const subnavList = el.lastElementChild;

                menuDeepLevel++;
                menuListStepAnimate(
                    parent,
                    subnavList,
                    menuDeepLevel
                );
            });
        });
    }

    function menuListStepAnimate(hideList, showList, level) {
        let hideListItems = hideList.children;
        hideListItems = Array.from(hideListItems);
        const hideListLinks = hideListItems.map((item) =>
            item.querySelector("li > a")
        );

        let showListItems = showList.children;
        showListItems = Array.from(showListItems);
        const showListLinks = showListItems.map((item) =>
            item.querySelector("li > a")
        );

        let width = window.innerWidth > 0 ? window.innerWidth : screen.width;

        if (width < 1199 || document.querySelector(".js-desktopMenu")) {
            timeline.clear();

            if (!deepLevelCheck(level)) {
                gsap.to(navBtnListBack, {
                    ease: "quart.in",
                    duration: 0.6,
                    opacity: 0,
                });
            }

            timeline.to(hideListLinks, {
                ease: "quart.out",
                stagger: 0,
                duration: 0,
                y: "0%",
                opacity: 0,
                onStart: () => {
                    // TOKNOW onStart not called
                    showList.classList.add("-is-active");
                },
                onComplete: () => {                    
                    // TOKNOW onStart not called
                    showList.classList.add("-is-active");
                    hideList.classList.remove("-is-active");
                },
            });

            if (deepLevelCheck(level)) {
                timeline.to(
                    navBtnListBack,
                    {
                        ease: "quart.inout",
                        duration: 0.4,
                        y: "0px",
                        opacity: 1,
                    },
                    ">-0.5"
                );
            }

            timeline.to(
                showListLinks,
                {
                    ease: "quart.in",
                    stagger: 0.08,
                    duration: 0.45,
                    y: "0%",
                    opacity: 1,
                },
                ">-0.6"
            );
        }
    }

    function headerSticky() {
        const header = document.querySelector(".js-header");
        if (!header) return;

        let classList = "";

        if (header.getAttribute("data-add-bg")) {
            classList = header.getAttribute("data-add-bg");
        }

        new ScrollMagic.Scene({ offset: "6px" })
            .setClassToggle(header, classList)
            .addTo(window['App'].SMcontroller);

        new ScrollMagic.Scene({ offset: "6px" })
            .setClassToggle(header, "is-sticky")
            .addTo(window['App'].SMcontroller);
    }

    return {
        headerSticky: headerSticky,
        init: init,
    };
})();

export const Events = (function () {
    function ddInit() {
        const targets = document.querySelectorAll(".js-form-dd");
        if (!targets) return;

        targets.forEach((el) => {
            const eventTarget = el.querySelector("[data-x-dd-click]");
            const attributes = eventTarget
                .getAttribute("data-x-dd-click")
                .split(", ");

            attributes.forEach((el2) => {
                const target = el.querySelector(`[data-x-dd=${el2}]`);
                const toggleClass = target.getAttribute("data-x-dd-toggle");

                eventTarget.addEventListener("click", () => {
                    if (eventTarget.querySelector(".js-dd-focus"))
                        eventTarget.querySelector(".js-dd-focus").focus();

                    if (target.classList.contains(toggleClass)) {
                        target.classList.remove(toggleClass);
                        el.classList.remove("-is-dd-wrap-active");
                    } else {
                        closeAllDropdowns();
                        target.classList.add(toggleClass);
                        el.classList.add("-is-dd-wrap-active");
                    }
                });
            });
        });
    }

    function closeAllDropdowns() {
        const classes = document.querySelectorAll(".-is-dd-wrap-active");
        if (classes) {
            classes.forEach((el) => {
                el.classList.remove("-is-dd-wrap-active");
            });
        }

        const targets = document.querySelectorAll(".js-form-dd");
        if (!targets) return;

        targets.forEach((el) => {
            const eventElement = el.querySelector("[data-x-dd]");
            const eventTarget = el.querySelector("[data-x-dd-click]");
            const attributes = eventTarget
                .getAttribute("data-x-dd-click")
                .split(", ");

            attributes.forEach((el) => {
                eventElement.classList.remove("-is-active");
                const target = document.querySelector(`[data-x-dd=${el}]`);
                const toggleClass = target.getAttribute("data-x-dd-toggle");
                target.classList.remove(toggleClass);
            });
        });
    }

    return {
        ddInit: ddInit,
        closeAllDropdowns: closeAllDropdowns,
    };
})();

export function closeAlldropdowns() {
    const targets = document.querySelectorAll(
        ".js-select, .js-multiple-select"
    );
    if (!targets) return;

    targets.forEach((el) => {
        if (el.querySelector(".-is-visible")) {
            el.querySelector(".-is-visible").classList.remove("-is-visible");
        }
    });
}