'use client'

import { ReactNode, useEffect } from "react";
import { Events } from "@/services/theme";

type ThemeProps = {
  children: ReactNode
}

export default function Theme({
    children
}: ThemeProps) {

    useEffect(() => {
        window.onclick = function (event) {
            if (
              !event.target.closest("[data-x-dd-click]") &&
              !event.target.closest("[data-x-dd]")
            ) {
              Events.closeAllDropdowns();
            }
        
            if (
              !event.target.classList.contains("dropdown__button") &&
              !event.target.classList.contains("js-dropdown-title")
            ) {
              const allDD = document.querySelectorAll(
                ".js-click-dropdown.-is-el-visible"
              );
              if (allDD) {
                allDD.forEach((el) => {
                  el.classList.remove("-is-el-visible");
                });
              }
        
              const allActiveDD = document.querySelectorAll(".-is-dd-active");
              if (allActiveDD) {
                allActiveDD.forEach((el) => el.classList.remove("-is-dd-active"));
              }
            }
        
            if (!event.target.closest(".js-select")) {
              const targets = document.querySelectorAll(".js-select");
              if (!targets) return;
        
              targets.forEach((el) => {
                if (el.querySelector(".-is-visible")) {
                  el.querySelector(".-is-visible").classList.remove("-is-visible");
                }
              });
            }
        
            if (!event.target.closest(".js-multiple-select")) {
              const targets = document.querySelectorAll(".js-multiple-select");
              if (!targets) return;
        
              targets.forEach((el) => {
                if (el.querySelector(".-is-visible")) {
                  el.querySelector(".-is-visible").classList.remove("-is-visible");
                }
              });
            }
          };
    }, [])

    return (
        <>{children}</>
    )
}